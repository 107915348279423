import { Box, Text, Grid, Image } from 'grommet';
import React from 'react';

const Member = props => {
    const { name, role, photoLink, description } = props.profile;

    return (
        <Box align="center">
            <Text size="xxlarge" weight="bold">{name}</Text>
            <Text size="large">{role}</Text>
            <Box height="xxlarge" margin="medium">
                <Image
                    fit="contain"
                    src={photoLink}
                />
            </Box>
            <Box height="xlarge" width="xlarge" margin="medium">
                <Text size="large">{description}</Text>
            </Box>
        </Box>
    );
};

export const Team = () => {
    const profileList = [
        {
            name: "Shalaka Dongre",
            role: "Owner of Flying Colors",
            photoLink: "./shalaka.jpeg",
            description: "I established Flying Colors Arts Studio to learn about arts, to exchange ideas and expand the imagination. Sky is the limit for ideas especially in art. The vision of Flying colors is to bring the imagination to reality. It’s an open stage to express your imagination and work towards it with experienced teachers and your buddies. I am passionate about arts, and my vision is to bring all types of arts to the class. Anyone of any age group who is willing to learn and contribute is welcome to Flying Colors Arts Studio.",
        },  
    ];

    return (
        <Grid columns="medium" rows="large" gap="xlarge" pad="large" margin={{
            left: "large", right: "large"
        }}>
            {
                profileList.map((profile, index) =>
                    <Member key={index} profile={profile} />
                )
            }
        </Grid >
    )
};
import { Box, Card as GrommetCard, CardBody, CardHeader, Image, Text, CardFooter } from "grommet";
import React from 'react';

export const Card = (props) => {

    return (
        <GrommetCard background="status-warning" pad="small" gap="medium" onClick={props.onClick}>
            <CardHeader alignSelf="center">
                <Text size="xxlarge" color="brand" weight="bold">
                    {props.displayName}
                </Text>
            </CardHeader>

            <CardBody>
                { <Image
                    fit="contain"
                     src="../public/logo.jpeg"
                />              
                }
                <Box align="center" pad={{ top: "medium" }}>
                    {props.icon}
                </Box>
            </CardBody>
            <CardFooter>
            background: "background-contrast"
            </CardFooter>
        </GrommetCard>
    );
};
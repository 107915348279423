import { Box, Button, Table, TableHeader, TableRow, TableCell, TableBody, Text, TextInput, Select, FileInput, TextArea } from 'grommet';
import { Add, FormEdit, FormTrash, Search } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { deleteStudentFromStudentList } from '../../api/students';
import { deleteUser, getAllUsers } from '../../api/users';
import { userTypes } from '../../lib/constants';
import { SpinnerComponent } from '../spinner/Spinner';
import { AddEditUserForm } from '../userData/AddEditUser';
//import PrintFunction from './PrintFunction';


// TODO: Add user id
export const InterestList = () => {
    const columnNames = ["Name", "User Type", "Email", "Phone Number"];
    const columnNamesForReadingFirebaseData = ["name", "userType", "email", "phoneNo"];
    const [shouldShowSpinner, setShouldShowSpinner] = useState(false);
    
    const defaultRows = [
        { "Name": "", "User Type": "", "Email": "", "Phone Number": "", "Emergency Contact Name": "", "Emergency Contact": "", "Date Of Birth": "", "Photo": "-", "Role": "", "Description": "", "Show In Teams Page": false }
    ];

    const [rows, setRows] = useState([]);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    
    //const [rowSelectedForEdit, setRowSelectedForEdit] = useState(-1);

    const setRowsFromData = (data) => {
        const newRows = [];
        Object.keys(data).sort().forEach(email => {
            newRows.push({ ...defaultRows, ...data[email] });
        })
        setRows(newRows);
    }

    useEffect(() => {
        getAllUsers((data) => {
            setRowsFromData(data);
        });
    }, []);
 
    const updateTable = () => {
        setShouldShowSpinner(true);
        setTimeout(() => {
            getAllUsers((data) => {
                setRowsFromData(data);
                setShouldShowSpinner(false);
            });
        }, 2000);
    }
    const rows1 = rows.map((row, index) => {
        const row1 = { ...row };
        return row1;
    });
    return (
        <>
            <Box pad="large" height="750px">
                <Text textAlign="left" size="xxlarge" weight="bold">Users</Text>
                <Box direction="row" justify="between" width="100%" margin={{ top: "medium", bottom: "medium" }}>
                <Box>
                   <TextInput icon={<Search color="brand" />} name="search" type="name" placeholder="Enter user name" onChange={event => setSearchKeyWord(event.target.value)} />
                   </Box>   
                   <Button
                        icon={<print />}
                        label="Print"
                        onClick={() => {  window.print(); }}
                    />  
                </Box>

                <Box overflow="scroll">
                    <Table>
                        <TableHeader>
                               <TableRow> {columnNames.map((colName, index) => (
                                    <TableCell key={index} scope="col" align="center" width="small">
                                        <Text>{colName}</Text>
                                    </TableCell>
                                ))}
                                </TableRow> 
                        </TableHeader>
                        <TableBody>
                            {
                                rows1.filter(row => row["name"].toLowerCase().includes(searchKeyWord.toLowerCase())).map((row, index) => (
                                    <TableRow key={index}>
                                        {
                                            columnNamesForReadingFirebaseData.map((colName, index2) => (
                                                <TableCell key={index2} width="small">
                                                    <Text truncate="tip" alignSelf="center" width="small">{row[colName]}</Text>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                       </Table> 
                    </Box>
                 </Box>
            </>      
    );
                               }
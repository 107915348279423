import React from "react";
import { Box, Card as GrommetCard, CardBody, CardHeader, Image, Text, Button } from "grommet";
import { StudentEnrollment } from "../studentEnrollment/StudentEnrollment";
import { HomePage } from "../HomePage";
import ReactDOM from 'react-dom/client'


function Card1(props){
    return (
        <div>
            <br/>
            <br/>
             <GrommetCard background="status-warning" pad="small" gap="small" width ="600px" height="600px" margin-left = "40px" margin ="30px" margin-right="10px" >
                <CardHeader >
                </CardHeader>
                <CardBody>
                    <Text size="medium" color="brand" weight="bold" justify="center">
                        {props.message}
                    </Text>
                    <br/>
                    <br/>
                    <Button onClick={(e) =>{
                        e.preventDefault();
                        window.location.href="https://docs.google.com/forms/d/e/1FAIpQLSdSEyLL-nSACii32feaee8Mqg6fqjMgY7v_fMFZ2Vz7haQipg/viewform?usp=sf_link";

                    }}
                        style={{
                        width: 150, 
                        height: 70,
                        margin: 'auto',
                        display: 'block',
                        background: '#19BCCE',
                        color: 'black',
                        border: '10px',
                        boxShadow: '10px',
                        cursor: 'pointer',
                        padding:'5px 25px',
                        borderRadius:'10px'
                        }}
                    pad={{ vertical: 'medium', horizontal: 'large'
                        }}>
                        
                            <Text size="xlarge" weight="bold" justify="center">
                            Register
                            </Text>
                            
                    </Button>                  
            
           </CardBody>
        </GrommetCard>
        </div>
        

    
    );
};

export default Card1;


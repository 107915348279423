import { Box, Grid } from 'grommet';
import {
    Analytics, ContactInfo, DocumentUser, Schedule, UserAdd, UserExpert
} from 'grommet-icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import { Card } from '../card/Card';
import { userTypes } from '../../lib/constants';
import { getUserData } from '../../api/users';

export const DashboardPage = () => {
    if (sessionStorage.getItem("email") == undefined || sessionStorage.getItem("email") == "undefined") {
        window.location.href = "home";
    }

    const [ userType, setUserType ] = useState(userTypes.NULL);

    useEffect(()=> {
        getUserData(sessionStorage.getItem("email"), (data)=> {
            if(data == undefined) {
                return;
            }
            setUserType(data.userType);
        })
    },[]);

    const [viewType, setViewType] = useState("DashboardPage1");

    const navigate = useNavigate();

    const cards = [];
    if (userType == userTypes.TEACHER || userType == userTypes.ADMIN_AND_TEACHER || userType == userTypes.ADMIN) {
        cards.push({ displayName: "User Data", icon: <DocumentUser size="200px" color="brand" />, route: "userData", access: [] });
        cards.push({ displayName: "Student Enrollment", icon: <UserAdd size="200px" color="brand" />, route: "studentEnrollment", access: [] });
        cards.push({ displayName: "Student CheckIn", icon: <UserExpert size="200px" color="brand" />, route: "studentCheckIn", access: [] });
        cards.push({ displayName: "Monthly Schedule", icon: <Schedule size="200px" color="brand" />, route: "monthlySchedule", access: [] });
        cards.push({ displayName: "Profile Information", icon: <ContactInfo size="200px" color="brand" />, route: "profile", access: [] });
        cards.push({ displayName: "Analytics", icon: <Analytics size="200px" color="brand" />, route: "analytics", access: [] });
       
    }
    else if(userType == userTypes.STUDENT) { 
        cards.push({ displayName: "Profile Information", icon: <ContactInfo size="200px" color="brand" />, route: "profile", access: [] });
    }
    
    return (
        <Box>
            {/* <Box direction="row" gap="medium"x>
                <Button primary onClick={() => setViewType("students")}>Students</Button>
                <Button primary onClick={() => setViewType("classes")}>Classes</Button>
            </Box> */}
            {/* {viewType === "students" && <Students />} */}
            {/* {viewType === "classes" && <Classes />} */}
            <Grid columns="medium" rows="medium" gap="xlarge" pad="large" margin={{
                left: "xlarge", right: "large"
            }}>
                {/* <Card name="Profile Information" />
                <Card name="Profile Information" />
                <Card name="Profile Information" />
                <Card name="Profile Information" />
                <Card name="Profile Information" /> */}
                {
                    cards.map((card, index) => <Card key={index} displayName={card.displayName} onClick={() => navigate("/" + card.route)} icon={card.icon} />)
                }

            </Grid >
        </Box>
    );
};
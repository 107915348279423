import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './App.css';
import React from 'react';

import { Main, Box, Button, Text, Grommet, Nav, Anchor, Footer } from 'grommet';
import {
  Icon, Grommet as GrommetIcon, Menu as MenuIcon, FacebookOption,
  Instagram
} from 'grommet-icons';

import { deepMerge } from 'grommet/utils';

import { grommet } from 'grommet';
import { AppHeader } from './components/Header';
import { Calendar } from './components/Calendar';
import { ComingSoon } from "./components/ComingSoon";
import { DashboardPage } from "./components/dashboard/DashboardPage";
import { Team } from "./components/Team";
import { MonthlySchedule } from "./components/classes/MonthlySchedule";
import { UserData } from "./components/userData/UserData";
import { StudentCheckInPage } from "./components/studentCheckIn/StudentCheckInPage";
import { StudentEnrollment } from "./components/studentEnrollment/StudentEnrollment";
import { HomePage } from "./components/HomePage";
import {InterestList} from "./components/analytics/InterestList";
import { AddEditClass } from "/Users/shalaka/Documents/flying-colors-arts-studio/frontend/src/components/classes/AddEditClass.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddEditClassForm } from './components/classes/AddEditClass';
//import { UsersignUp } from '../api/firebaseHelper';  
import ClassesInfo from './components/classes/ClassesInfo';
import Card1 from './components/card/Card1.jsx'








export const myCustomTheme = deepMerge(grommet, {
  global: {
    colors: {
      brand: '#8c182b',
    },
  },
});

const Media = () => (
  <Box direction="row" gap="xxsmall" justify="center">
    <Anchor
      a11yTitle="Share feedback on Instagram"
      href="https://www.instagram.com/flyingcolorsartsstudio/"
      icon={<Instagram color="brand" />}
    />
    <Anchor
      a11yTitle="Chat with us on Facebook"
      href="https://www.facebook.com/profile.php?id=61555543800098"
      icon={<FacebookOption color="brand" />}
    />
  </Box>
);

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //    >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <Grommet theme={myCustomTheme}>
      <Main>
        <AppHeader />

        {/* <Text>
          The Button font is taken from the grommet theme, while its
          brand color is taken from myCustomTheme.
          The Button font is taken from the grommet theme, while its
          brand color is taken from myCustomTheme.
          The Button font is taken from the grommet theme, while its
          brand color is taken from myCustomTheme.
          The Button font is taken from the grommet theme, while its
          brand color is taken from myCustomTheme.
        </Text> */}

        <BrowserRouter>
          <Routes>
            {/* <Route index element={<ComingSoon />} /> */}
            <Route path="home" element={<HomePage />} />
            <Route path="classes" element={<ClassesInfo />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="team" element={<Team />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="userData" element={<UserData />} />
            <Route path="studentEnrollment" element={<StudentEnrollment />} />
            <Route path="monthlySchedule" element={<MonthlySchedule />} />
            <Route path="studentCheckIn" element={<StudentCheckInPage />} />
            <Route path="analytics" element={<InterestList />} />
            <Route path="profile" element={<DashboardPage />} />
            <Route path="*" element={<Navigate to="/home" />} />
            <Route path="Classesr" element={<MonthlySchedule />}/>
          </Routes>
        </BrowserRouter>

        <Footer direction="column" background="status-warning" pad="small">
          <Box direction="row" gap="large" justify="center">
            <Box direction="column" gap="xxsmall" justify="center">
              <Box direction="column" gap="xxsmall" justify="center">
                <Text textAlign="center" size="medium" weight="bold" color="black">
                  Flying Colors Arts Studio
                </Text>
                <Text textAlign="center" size="medium" weight="bold" color="black">
                  4309 S Alston Ave
                  Durham, NC 27713
                </Text>
              </Box>
            </Box>
            <Box direction="column" gap="xxsmall" justify="center">
              <Box direction="row-responsive">
                <Text textAlign="left" size="medium" weight="bold" color="black" margin={{ right: "small" }}>
                  Shalaka Dongre
                </Text>
                <Text textAlign="left" size="medium" weight="bold" color="black">
                  408-835-7854
                </Text>
              </Box>
              <Text textAlign="left" size="medium" color="black">
                Hours: Tue,Thu 4-7pm, Wed 4-9pm, Sat 10-4pm
              </Text>
            </Box>
            <Box direction="column" gap="xxsmall" justify="center">
              <Text textAlign="center" size="xlarge" color="black">
                What's Happening
              </Text>
              <Box direction="column" gap="xxsmall" justify="center">
                <Anchor textAlign="center" size="medium" href="/classes">
                  Classes
                </Anchor> 
                <Anchor textAlign="center" size="medium" href="/Calendar">
                  Calendar 
                </Anchor>
              </Box>
            </Box>
          </Box>
          <Media />
          <Text textAlign="center" size="small" color="black">
            @2022 Flying Colors Arts Studio
          </Text>
        </Footer>
      </Main>
    </Grommet>
  );
}

export default App;

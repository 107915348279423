import { removeSpecialCharactersFromString } from "../lib/stringHelper";
import { readData, writeData } from "./firebaseHelper";

// TODO: Costliest operation. Try to replace by studentList
export const getAllUsers = (callback) => {
    readData("Users", callback);
}

export const getUserData = (email, callback) => {
    readData("Users/" + removeSpecialCharactersFromString(email), callback);
}

export const getTeachersList = (callback) => {
    readData("Users", (data) => {
        console.log(data, 222222);
        const teachersList = [];
        Object.keys(data).forEach(email => {
            console.log(data[email].userType);
            if (data[email].userType == "Teacher") {
                teachersList.push(data[email].name);
            }
        })
        callback(teachersList);
    });
}

export function addEditUser(data) {
    writeData("Users/" + removeSpecialCharactersFromString(data.email), data);
}

export function deleteUser(data) {
    writeData("Users/" + removeSpecialCharactersFromString(data.email));
}

import { Box, Button, Text, Tab, Tabs } from "grommet";
import {
    FormNext, FormPrevious
} from 'grommet-icons';
import { useState } from "react";
import { Event } from "./Event";
import React from 'react';

const data = [
    {
        "id": 0,
        "date": {
            "day": 1,
            "month": 9,
            "year": 2021
        },
        "text": "DAILY DRAWING & PAINTING STARTING 1ST WEEK OF EACH MONTH ",
        "description": "This is Classic basic Art drawing painting, level 1,2,3 (Teacher will assess the student on the first day of the class Monthly 4 classes of 1 hour offered.Kids need to bring the supplies.The List will be provided on the first day of the class"
    },
    {
        "id": 1,
        "date": {
            "day": 8,
            "month": 9,
            "year": 2021
        },
        "text": "Craft day - Making lantern",
        "description": "Learn how to make beautiful Lanterns made by papers and other decoration material. material will be provided"

    },
    {
        "id": 2,
        "date": {
            "day": 15,
            "month": 9,
            "year": 2022
        },
        "text": "Paint with Christine ",
        "description": "Fun and creative event - learn basic strokes shading different techniqus, bring your own idea and supplies"
    },
    {

        "id": 3,
        "date": {
            "day": 22,
            "month": 9,
            "year": 2022
        },
        "text": "Face painting workshop",
        "description": "Basic face painting class with lots of information and entertainment like karaoke"

    },
    {
        "id": 4,
        "date": {
            "day": 1,
            "month": 10,
            "year": 2022
        },
        "text": "DAILY DRAWING & PAINTING STARTING 1ST WEEK OF EACH MONTH ",
        "description": "This is Classic basic Art drawing painting, level 1,2,3 (Teacher will assess the student on the first day of the class Monthly 4 classes of 1 hour offered.Kids need to bring the supplies.The List will be provided on the first day of the class"
    },
    {
        "id": 5,
        "date": {
            "day": 5,
            "month": 10,
            "year": 2022
        },
        "text": "Craft day - paper mache",
        "description": "Learn how to make beautiful paper mache made by papers and other decoration material. material will be provided"

    },
    {
        "id": 6,
        "date": {
            "day": 16,
            "month": 10,
            "year": 2022
        },
        "text": "Paint with Jane",
        "description": "Fun and creative event - learn basic strokes shading different techniqus, bring your own idea and supplies"
    },
    {
        "id": 7,
        "date": {
            "day": 22,
            "month": 10,
            "year": 2022
        },
        "text": "Glass painting workshop",
        "description": "Basic glass painting class with lots of information and entertainment like karaoke"

    },
    {
        "id": 8,
        "date": {
            "day": 1,
            "month": 11,
            "year": 2022
        },
        "text": "DAILY DRAWING & PAINTING STARTING 1ST WEEK OF EACH MONTH ",
        "description": "This is Classic basic Art drawing painting, level 1,2,3 (Teacher will assess the student on the first day of the class Monthly 4 classes of 1 hour offered.Kids need to bring the supplies.The List will be provided on the first day of the class"
    },
    {
        "id": 9,
        "date": {
            "day": 8,
            "month": 11,
            "year": 2023
        },
        "text": "Craft day - Making lantern",
        "description": "Learn how to make beautiful Lanterns made by papers and other decoration material. material will be provided"


    },
    {
        "id": 10,
        "date": {
            "day": 15,
            "month": 11,
            "year": 2023
        },
        "text": "Paint with Christine",
        "description": "Fun and creative event - learn basic strokes shading different techniqus, bring your own idea and supplies"
    },
    {
        "id": 11,
        "date": {
            "day": 22,
            "month": 11,
            "year": 2023
        },
        "text": "Face painting workshop",
        "description": "Basic face painting class with lots of information and entertainment like karaoke"

    }
];



export const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const Calendar = () => {
    const todayDate = new Date();
    const [date, setDate] = useState({ month: todayDate.getMonth() + 1, year: todayDate.getFullYear() });
    const [expandedEventId, setExpandedEventId] = useState(undefined);

    const incrementMonth = () => {
        const newDate = { ...date };
        if (date.month == 12) {
            newDate.month = 1;
            newDate.year++;
        } else {
            newDate.month++;
        }

        setDate(newDate);
    };

    const decrementMonth = () => {
        const newDate = { ...date };
        if (date.month == 1) {
            newDate.month = 12;
            newDate.year--;
        } else {
            newDate.month--;
        }

        setDate(newDate);
    };

    const filteredData = data.filter(row => row.date.month == date.month && row.date.year == date.year);

    console.log(date, expandedEventId, "date");
    return (
        <Box height="medium" margin="medium">
            <Box direction="row">
                <Text>{months[date.month - 1] + ", " + date.year}</Text>
                <Button
                    icon={<FormPrevious />}
                    onClick={decrementMonth}
                    hoverIndicator
                />
                <Button
                    icon={<FormNext />}
                    onClick={incrementMonth}
                    hoverIndicator
                />
            </Box>
            <Box gap="small" overflow="scroll">
                {filteredData.map(event => (
                    <Event key={event.id} event={event} shouldExpand={expandedEventId == event.id} onClick={() => setExpandedEventId(expandedEventId == event.id ? undefined : event.id)} />
                ))}
            </Box>
            {/* <Tabs
                justify="start"
                alignControls="start"
            // activeIndex={index}
            // onActive={onActive}
            >
                <Tab title="Tab 1">
                    abc
                </Tab>
                <Tab title="Tab 2">
                    def
                </Tab>
                <Tab title="Tab 3">
                    ghi
                </Tab>
            </Tabs > */}
        </Box >
    );
};
import { removeSpecialCharactersFromString } from "../lib/stringHelper";
import { readData, writeData } from "./firebaseHelper";

export const getStudentList = (callback) => {
    readData("StudentList", callback);
}

export function setStudentList(data) {
    writeData("StudentList/", data);
}

export function addInStudentList(name, email) {
    readData("StudentList", (data) => {
        const newStudentList = [];
        if (data != null && data != "") {
            if (Object.values(data).includes(name + " " + email)) {
                return;
            }
            Object.values(data).forEach(val => newStudentList.push(val));

            newStudentList.push(name + " " + email);
            setStudentList(newStudentList);
        }
    });
}

export function deleteStudentFromStudentList(name, email) {
    readData("StudentList", (data) => {
        let newStudentList = [];
        if (data != null && data != "") {
            newStudentList = Object.values(data).filter(val => val !== (name + " " + email));
            setStudentList(newStudentList);
        }
    });
}

export const getStudentCreditData = (email, callback) => {
    readData("Users/" + removeSpecialCharactersFromString(email) + "/creditsData", callback);
}

export function setStudentCreditData(email, data) {
    writeData("Users/" + removeSpecialCharactersFromString(email) + "/creditsData", data);
}

export function reduceStudentCredits(email, className) {
    getStudentCreditData(email, (data) => {
        const newCreditData = [];
        if (data == null || data == '') {
            newCreditData.push({ availableCredits: -1, className });
        } else {
            Object.values(data).forEach(val => {
                if (val.className == className) {
                    newCreditData.push({ ...val, availableCredits: val.availableCredits - 1 });
                } else {
                    newCreditData.push(val);
                }
            });

            if (Object.values(data).filter(val => val.className == className).length == 0) {
                newCreditData.push({ availableCredits: -1, className });
            }
        }

        setStudentCreditData(email, newCreditData);
    });
}

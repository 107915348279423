import { Box, Button, Form, FormField, Layer, Table, TableHeader, TableRow, TableCell, TableBody, Text, TextInput, Select, FileInput, TextArea } from 'grommet';
import { Add, Search, FormEdit, FormTrash, Close } from 'grommet-icons';
import { useEffect, useState } from 'react';
import React from 'react';
import { deleteClass, getAllClasses } from '../../api/classes';
import { SpinnerComponent } from '../spinner/Spinner';
import { AddEditClassForm } from './AddEditClass';

export const MonthlySchedule = () => {
    const columnNames = ["Name", "Schedule", "Teacher", "Cost", "Edit", "Delete"];
    const columnNamesForReadingFirebaseData = ["name", "schedule", "teacher", "cost", "Edit", "Delete"];

    const [shouldShowAddEditClassModal, setShouldShowAddEditClassModal] = useState(false);
    const [shouldShowSpinner, setShouldShowSpinner] = useState(false);
    const [addEditClassFormType, setAddEditClassFormType] = useState("add");

    const defaultRows = [
        { "Name": "", "Schedule": "", "Teacher": "", "Cost": "" },
    ];

    const [rows, setRows] = useState([]);

    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [rowSelectedForEdit, setRowSelectedForEdit] = useState(-1);


    // const [shouldShowNewClassModel, setShouldShowNewClassModel] = useState(false);

    const setRowsFromData = (data) => {
        if (data == undefined) {
            setRows([]);
            return;
        }
        const newRows = [];
        Object.keys(data).sort().forEach(className => {
            newRows.push({ ...defaultRows, ...data[className] });
        })
        setRows(newRows);
    }

    useEffect(() => {
        getAllClasses((data) => {
            setRowsFromData(data);
        });
    }, []);

    const updateTable = () => {
        setShouldShowSpinner(true);
        setTimeout(() => {
            getAllClasses((data) => {
                setRowsFromData(data);
                setShouldShowSpinner(false);
            });
        }, 2000);
    }

    const rows1 = rows.map((row, index) => {
        const row1 = { ...row };

        row1["Edit"] = <Button icon={<FormEdit color="brand" onClick={() => { setRowSelectedForEdit(index); setAddEditClassFormType("edit"); setShouldShowAddEditClassModal(true); }} />} />;
        row1["Delete"] = <Button icon={<FormTrash color="brand" onClick={() => {
            if (window.confirm("Are you sure you want to delete this user?")) {
                deleteClass(row);
                getAllClasses((data) => {
                    setRowsFromData(data);
                });
            }
        }} />} />;
        return row1;
    });

    return (
        <>
            <Box pad="large" height="750px">
                <Text textAlign="left" size="xxlarge" weight="bold">Users</Text>
                <Box direction="row" justify="between" width="100%" margin={{ top: "medium", bottom: "medium" }}>
                    <Box>
                        <TextInput icon={<Search color="brand" />} name="search" type="name" placeholder="Enter user name" onChange={event => setSearchKeyWord(event.target.value)} />
                    </Box>
                    <Button
                        icon={<Add />}
                        label="Add"
                        onClick={() => { setRowSelectedForEdit(-1); setAddEditClassFormType("add"); setShouldShowAddEditClassModal(true); }}
                    />
                </Box>
                <Box overflow="scroll">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                {columnNames.map((colName, index) => (
                                    <TableCell key={index} scope="col" align="center" width="small">
                                        <Text>{colName}</Text>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                rows1.filter(row => row["name"].toLowerCase().includes(searchKeyWord.toLowerCase())).map((row, index) => (
                                    <TableRow key={index}>
                                        {
                                            columnNamesForReadingFirebaseData.map((colName, index2) => (
                                                <TableCell key={index2} width="small">
                                                    <Text truncate="tip" alignSelf="center" width="small">{row[colName]}</Text>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
            {shouldShowAddEditClassModal && <AddEditClassForm formType={addEditClassFormType} classData={rowSelectedForEdit >= 0 ? rows[rowSelectedForEdit] : undefined} updateTable={updateTable} onClose={() => setShouldShowAddEditClassModal(false)} />}
            {shouldShowSpinner && <SpinnerComponent />}
        </>
    );
};
import { Box, Button, Layer, Text, TextInput, Form, FormField } from 'grommet';
import { Grommet as GrommetIcon, Close, Google } from 'grommet-icons';
import { React, useState } from 'react';
import firebase from 'firebase/compat/app';
import { DashboardPage } from './dashboard/DashboardPage';

export const LoginModal = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('!');

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(email, password);
        // Send the email and password values to the server for authentication
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                // Sign-in successful
                console.log("Sign-in successful!");
                sessionStorage.setItem("email", email);
                props.onClose();
                window.location.href = "DashboardPage";
            })
            .catch((error) => {
                // Handle Errors here.
                alert("Invalid Credentials");
                console.log(111111);
                var errorCode = error.code;
                var errorMessage = error.message;
                console.error(errorCode, errorMessage);
            });
    }

    return (
        <Layer>
            <Box
                align="center"
                justify="center"
                gap="small"
                direction="column"
                alignSelf="center"
                pad="small"
            >
                <Box direction="horizontal" width="100%" justify="end">
                    <Button icon={<Close />} onClick={props.onClose} />
                </Box>
                <Box
                    align="center"
                    justify="center"
                    gap="small"
                    direction="column"
                    alignSelf="center"
                    pad="medium"
                >
                    <GrommetIcon size="large" color="red" />
                    <Text size="xlarge">Flying Colors Arts Studio</Text>
                    <Text>Welcome back!</Text>
                </Box>
                <Box>
                    <Form onSubmit={handleSubmit}>
                        <FormField
                            label="Email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                        <FormField
                            label="Password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                        />
                        <Button type="submit" label="Submit" primary />
                        <Box height="15px" />
                    </Form>
                </Box>
            </Box>
        </Layer>
    )
};

// import React, { useState } from 'react';
// import { Box, Form, FormField, Button } from 'grommet';

// export const LoginModal = () => {
//     // State to store the email and password values
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         // Send the email and password values to the server for authentication
//     }

//     return (
//         <Box>
//             <Form onSubmit={handleSubmit}>
//                 <FormField
//                     label="Email"
//                     name="email"
//                     type="email"
//                     value={email}
//                     onChange={event => setEmail(event.target.value)}
//                 />
//                 <FormField
//                     label="Password"
//                     name="password"
//                     type="password"
//                     value={password}
//                     onChange={event => setPassword(event.target.value)}
//                 />
//                 <Button type="submit" label="Submit" primary />
//             </Form>
//         </Box>
//     );
// }

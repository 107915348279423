import React from 'react';
// import { Spinning } from 'grommet-icons';
import { Box, Grommet, Layer, Spinner } from 'grommet';

export const SpinnerComponent = () => (
    <Layer
        align="center"
        justify="center"
        pad="large"
    >
        <Spinner
            size="large"
        // icon={<Spinning />}
        />
    </Layer>
);

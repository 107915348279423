import { removeSpecialCharactersFromString } from "../lib/stringHelper";
import { readData, writeData } from "./firebaseHelper";

export const getAllClasses = (callback) => {
    readData("Classes", callback);
}

export function addEditClass(data) {
    writeData("Classes/" + removeSpecialCharactersFromString(data.name), data);
}

export function deleteClass(data) {
    writeData("Classes/" + removeSpecialCharactersFromString(data.name));
}

import { Box, Button, Image, Layer } from 'grommet';
import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import {Calligraphy, Classical, Experimental,Progressive} from '../lib/constants.js';


function CustomAlert({ onClose, message, position, background,scrollRef }) {

  const layerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (layerRef.current) {
        const scrollTop = window.scrollY;
        layerRef.current.style.top = position.current.y - scrollTop + 'px';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position]);

  return (
    <Layer
    modal={false}  // Set modal to false
      onClickOutside={onClose}
      onEsc={onClose}
      style={{
          position: 'absolute',
          top: position.current.y,
          left: position.current.x,
          width: 700, 
          height: 400,
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          background: '#abb8c3',
          color: 'black',
          fontFamily:'Avenir Next',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '10px',  // Adjust the border-radius for curvature
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
          
        }}
        ref={layerRef}
    >
        <div ref={scrollRef}>{message}</div>
        <Button 
        style={{
          position: 'absolute',
          top: position.y,
          left: position.x,
          width: 700, 
          height: 370,
          background: 'transparent',
          color: 'white',
          border: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
        }}
        
            onClick={onClose} />
    </Layer>
  );
}







export const HomePage = () => {
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [showAlert4, setShowAlert4] = useState(false);
  const clickPosition = useRef({ x: 0, y: 0 });



const handleButtonClick1 = (event) => {
    setShowAlert1(true);
    clickPosition.current = { x: event.clientX, y: event.clientY };
    
  };

  const handleCloseAlert1 = () => {
    setShowAlert1(false);
  };

  const handleButtonClick2 = (event) => {
    setShowAlert2(true);
    clickPosition.current = { x: event.clientX, y: event.clientY };
   
  };

  const handleCloseAlert2 = () => {
    setShowAlert2(false);
  };

   const handleButtonClick3 = (event) => {
    setShowAlert3(true);
    clickPosition.current = { x: event.clientX, y: event.clientY };
    
  };

  const handleCloseAlert3 = () => {
    setShowAlert3(false);
  };

  const handleButtonClick4 = (event) => {
    setShowAlert4(true);
    clickPosition.current = { x: event.clientX, y: event.clientY };
    
  };

  const handleCloseAlert4 = () => {
    setShowAlert4(false);
  };

  return (
    <Box height="xlarge" align="center">
      <Image fit="contain" src={'FCLetterJan232024.jpg'} />

      {/* First Button */}
      <Button
        style={{
          position: 'absolute',
          top: '62%',
          left: '66%',
          width: 240, 
          height: 210,
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          background: 'transparent',
          color: 'white',
          border: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
        }}
        onClick={handleButtonClick1}
      />
      
      {showAlert1 && <CustomAlert onClose={handleCloseAlert1} message = {Classical} position={clickPosition} background='#f5f5f5' />}

      {/* seconde Button */}
      <Button
        style={{
          position: 'absolute',
          top: '96%',
          left: '66%',
          width: 240, 
          height: 210,
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          background: 'transparent',
          color: 'white',
          border: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
        }}
        onClick={handleButtonClick2}
      />

      {showAlert2 && <CustomAlert onClose={handleCloseAlert2} message = {Progressive} position={clickPosition} background={'#50ccb3'} />}
  
      {/* Third Button */}
      <Button
        style={{
          position: 'absolute',
          top: '130%',
          left: '66%',
          width: 240, 
          height: 210,
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          background: 'transparent',
          color: 'white',
          border: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
        }}
        onClick={handleButtonClick3}
      />

      {showAlert3 && <CustomAlert onClose={handleCloseAlert3} message = {Experimental} position={clickPosition} background={'#90d275'} />}
    
    {/* forth Button */}
      <Button
        style={{
          position: 'absolute',
          top: '119%',
          left: '41%',
          width: 400, 
          height: 395,
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          background: 'transparent',
          color: 'white',
          border: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
        }}
        onClick={handleButtonClick4}
      />
      
      {showAlert4 && <CustomAlert onClose={handleCloseAlert4} message = {Calligraphy} position={clickPosition} background='#f5f5f5' />}
    </Box>
  
  );
};
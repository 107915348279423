export const userTypes = Object.freeze({
    STUDENT: 'Student',
    TEACHER: 'Teacher',
    ADMIN_AND_TEACHER: 'Admin and Teacher',
    ADMIN: 'Admin',
    NULL: 'NULL'
});

export  const Classical =(
    <div>
    
    <p>CLASSICAL - DRAWING with CHRISTINE MACLELLAN </p>
        <p> 
            Learn the basics of drawing and sharpen your skills. We will cover light and shadow,<br />
            line and composition,blending, proportions and perspective<br /><br />
            Student bring their own supplies. <br/>
            Material available to purchase at the studio if needed.<br /><br />
            Classes are 1 hour once a week for a 8 weeks<br />
            Classes are onging<br /><br/>
            Fees : $200 for 8 classes (1 hour each class) <br/>
            Time - Tue,Wed,Thu  4-7pm, sat 10-4pm<br />
       </p>
    </div>
    )
  
    export const Progressive =(
      <div>
    
        <p>PROGRESSIVE - PAINTING WITH THE IMPRESSIONISTS with CHRISTINE MACLELLAN </p>
                  <p> Learn the techniques the master used to expressively<br/>
                      capture the figure, landsacpe, and still life.<br/>
                      Learn color mixing,toning the canvas,brushwork and more.<br/><br/>
                      Student bring their own supplies. <br/>
                      Material is available to purchaseat the studio if needed.<br/><br/>
                      Classes are two hours, once a week for a four week session<br/>
                      Classes are onging<br/><br/>
                      Fees : $200 for 4 classes (2 hour each class) <br/>
                      Time : Wednesday 7-9 pm, Saturday 2-4 pm <br/>
                    </p>
                    </div>
    )
    export const Experimental = (
      <div>
        <p>EXPERIMENTAL - MULTI-MEDIA CLASS with  CHRISTINE MACLELLAN </p>
          Learn watercolor,pastel,acrylic,oil and charcoal techniques and how to<br/>
          combine them to achieve dramatic atmospheric efforts.<br/>
          Students will work from their own photographs,sketches or still life.<br/>
          All levels welcomed!<br/><br/>
          Students will bring their own supplies. <br/>
          Materials are available for purchase at the studio<br/><br/>
          Classes are 1 hour, once a week for a 8 weeks<br/>
          Classes are onging<br/><br/>
          Fees : $200 for 8 classes (1 hour each class) <br/>
          Time - Tue,Wed,Thu  4-7pm, sat 10-4pm<br />
    
      </div>
    )
    export const Calligraphy = (
      <div>
      
      <p>CALLIGRAPHY WITH LYNN LOSITO </p>
          <p> 
              Calligraphy is a visual art related to writing <br />
              and is the design and execution of lettering with a pen ink<br /><br />
              Student bring their own supplies. <br/>
              Material available to purchase at the studio if needed.<br /><br />
              Classes are 1 hour once a week for a 8 weeks<br />
              Classes are onging<br /><br/>
              Fees : $200 for 8 classes (1 hour each class) <br/>
              Time - Tue,Wed,Thu  4-7pm, sat 10-4pm<br />
         </p>
      </div>
      )  
      
    
import React, { useEffect, useState } from 'react';
import { Form, FormField, Select, Button, Box, Text, Heading, Layer, List, DataTable } from 'grommet';
import { FormClose } from "grommet-icons";
import { userTypes } from "../../lib/constants";
import _ from "lodash";
import { getCheckinList, setCheckInList } from '../../api/checkInList';
import { getDateInMMDDYYAndTime } from '../../lib/date';
import { getStudentList, reduceStudentCredits } from '../../api/students';
import { getAllClasses } from '../../api/classes';

const CheckInForm = (props) => {

    // const studentOptions = [
    //     'John Doe jDoe@gmail.com', 'Jane Smith JSmith@gmail.com', 'Bob Johnson BJohnson@gmail.com'
    // ];

    const classOptions = [
        'Math 101', 'Science 102', 'History 103'
    ];

    const [showValidationMessge, setShowValidationMessage] = useState(false);

    const [studentName, setStudentName] = useState('');
    const [className, setClassName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (studentName == '' || className == '') {
            setShowValidationMessage(true);
        } else if (props.checkInList.filter(row => row.studentName == studentName).length > 0) {
            setShowValidationMessage(false);
            alert("Student already added in the checkin list");
        } else {
            setShowValidationMessage(false);

            console.log(`Selected student: ${studentName}, Selected class: ${className}`);
            setStudentName('');
            setClassName('');

            const newCheckinList = _.cloneDeep(props.checkInList);
            newCheckinList.push({ studentName, className, time: getDateInMMDDYYAndTime() });

            props.setCheckInList(newCheckinList);
            setCheckInList(newCheckinList);
        }
    }

    const [originalStudentOptions, setOriginalStudentOptions] = useState([]);
    const [studentOptions1, setStudentOptions] = useState([]);
    const [studentSearched, setStudentSearched] = useState('');

    useEffect(() => getStudentList((data) => {
        if (data != null && data != '') {
            const newStudentOptions = [];
            Object.values(data).forEach(val => newStudentOptions.push(val));
            setOriginalStudentOptions(newStudentOptions);
            setStudentOptions(newStudentOptions);
        }
    }), []);

    const handleStudentSearch = (value) => {
        setStudentSearched(value);
        setStudentOptions(originalStudentOptions.filter(option => option.toLowerCase().includes(value.toLowerCase())));
    }

    const [classOptions1, setClassOptions] = useState([]);
    const [classSearched, setClassSearched] = useState('');

    useEffect(() => getAllClasses((data) => {
        if (data != null && data != '') {
            const newClassOptions = [];
            Object.values(data).forEach(val => newClassOptions.push(val.name));
            setClassOptions(newClassOptions);
        }
    }), []);

    const handleClassSearch = (value) => {
        setClassSearched(value);
        setClassOptions(classOptions.filter(option => option.toLowerCase().includes(value.toLowerCase())));
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormField label='Student Name' value={studentName}>
                <Select
                    options={studentOptions1}
                    value={studentSearched}
                    onChange={({ value }) => setStudentName(value)}
                    placeholder={(studentName == undefined || studentName == "") ? 'Select a student' : studentName}
                    onSearch={handleStudentSearch}
                />
            </FormField>
            <FormField label='Class Name' value={className}>
                <Select
                    options={classOptions1}
                    value={classSearched}
                    onChange={({ value }) => setClassName(value)}
                    placeholder={(className == undefined || className == "") ? 'Select a class' : className}
                    onSearch={handleClassSearch}
                />
            </FormField>
            {showValidationMessge && <Box margin="small"><Text>Enter student name and class name</Text></Box>}
            <Button type='submit' margin="small" primary><Text margin="small">Check In</Text></Button>
        </Form>
    );
}

const CheckinList = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    return (
        <Box>
            {props.checkInList?.length > 0 && <Heading level={3}>Checked In Students</Heading>}
            <List
                data={props.checkInList}
                children={(row) => (
                    <Box direction="row" align="center" margin={{ left: "large", right: "large" }} justify="between">
                        <Box width="small">
                            <Text>{row.studentName}</Text>
                        </Box>
                        <Box width="small">
                            <Text>{row.className}</Text>
                        </Box>
                        <Box width="small">
                            <Text>{row.time}</Text>
                        </Box>
                        <Button
                            onClick={() => {
                                setSelectedStudent(row);
                                setOpen(true);
                            }}
                            primary
                        >
                            <Text margin="small">Checkout</Text>
                        </Button>
                    </Box>
                )}
            />
            {open && (
                <Layer
                    onEsc={() => setOpen(false)}
                    onClickOutside={() => setOpen(false)}
                >
                    <Box pad="medium" gap="small">
                        <Heading level={3}>Confirm Checkout</Heading>
                        <Text>
                            Are you sure you want to check out {selectedStudent.studentName}?
                        </Text>
                        <Box
                            direction="row"
                            gap="small"
                            align="center"
                            justify="end"
                            pad={{ top: 'medium' }}
                        >
                            <Button
                                label="Cancel"
                                onClick={() => setOpen(false)}
                            />
                            <Button
                                label="Confirm"
                                onClick={() => {
                                    const newCheckInList = props.checkInList.filter(row => selectedStudent.studentName != row.studentName);
                                    props.setCheckInList(newCheckInList);
                                    setCheckInList(newCheckInList);
                                    reduceStudentCredits(selectedStudent.studentName.split(" ").pop(), selectedStudent.className);
                                    // TODO: Add class attendance
                                    setOpen(false);
                                }}
                                primary
                            />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    );
};



export const StudentCheckInPage = () => {
    if (sessionStorage.getItem("email") == undefined || sessionStorage.getItem("email") == "undefined") {
        window.location.href = "home";
    }

    const userType = userTypes.TEACHER;

    if (userType == userTypes.STUDENT) {
        window.location.href = "home";
    }

    const [checkInList, setCheckInList] = useState([]);

    useEffect(() => {
        getCheckinList((data) => {
            if (data == null || data == "") {
                setCheckInList([]);
            } else {
                const data1 = [];
                Object.values(data).forEach(val => data1.push(val))
                setCheckInList(data1);
            }
        });
    }, []);

    return (
        <Box>
            <CheckInForm checkInList={checkInList} setCheckInList={setCheckInList} />
            <CheckinList checkInList={checkInList} setCheckInList={setCheckInList} />
            <Box margin="16%" />
        </Box>
    );
};
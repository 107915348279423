export function getDateInMMDDYYAndTime() {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    var seconds = date.getSeconds();
    var minutes = date.getMinutes();
    var hour = date.getHours();

    return month + "/" + day + "/" + year + " " + hour + ":" + minutes + ":" + seconds;
}
import { Nav, Anchor, Header, Image, Box } from 'grommet';
import { Grommet as GrommetIcon } from 'grommet-icons';
import { useState } from 'react';
import { LoginModal } from './LoginModal';
import React from 'react';
import firebase from 'firebase/compat/app';

export const AppHeader = () => {
    const email = sessionStorage.getItem("email");
    console.log(email, email == "undefined", email != undefined && email.length > 0);

    const [shouldShowLoginModal, setShouldShowLoginModal] = useState(false);

    const logoutUser = () => {
        firebase.auth().signOut();
        sessionStorage.setItem("email", undefined);
        window.location.href = "/home";
    };

    return (
        <>
            <Header sticky="scrollup" background="status-warning" pad="small">
                <Box>
                    <Anchor
                        href="/home"
                        icon={<Box width="5%" height="5%"><Image fit="cover" src="./logo.jpeg" /></Box>}
                        label="Flying Colors Arts Studio"
                    />
                </Box>

                {/* <Icon color="brand" /> */}
                {(email == undefined || email == "undefined") ?
                    <Nav direction="row">
                        <Anchor label="Home" href="/home" />
                        <Anchor label="Classes" href="/classes" />
                        <Anchor label="Calendar" href="/calendar" />
                        <Anchor label="Team" href="/team" />
                        <Anchor label="Login" onClick={() => setShouldShowLoginModal(true)} />
                    </Nav>
                    :
                    <Nav direction="row">
                        <Anchor label="Dashboard" href="/dashboard" />
                        <Anchor label="Logout" onClick={logoutUser} />
                    </Nav>
                }
            </Header>

            {shouldShowLoginModal && <LoginModal onClose={() => setShouldShowLoginModal(false)} />}
        </>
    )
};
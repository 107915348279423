import { Box, Button, Form, FormField, Layer, Text, TextInput, Select, FileInput, TextArea } from 'grommet';
import { Close } from 'grommet-icons';
import { useEffect, useState } from 'react';
import React from 'react';
import { addEditClass } from '../../api/classes';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { removeSpecialCharactersFromString } from '../../lib/stringHelper';
import { getTeachersList } from '../../api/users';

export const AddEditClassForm = (props) => {
    const defaultValue = props.classData ?? { name: "", schedule: JSON.stringify([]), photo: "" }
    const [value, setValue] = useState(defaultValue);

    const weekDaysList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const handleWeekDaysSelect = ({ option: newSelectedWeekDay }) => {
        const newSelectedWeekDays = weekDaysList.filter(weekDay => value.schedule.includes(weekDay) ^ (newSelectedWeekDay == weekDay));
        setValue({ ...value, schedule: JSON.stringify(newSelectedWeekDays) });
    };

    const [teachersList, setTeachersList] = useState([]);
    const [classTeacher, setClassTeacher] = useState("");

    useEffect(() => { getTeachersList(setTeachersList) }, []);

    const roomNoList = ["1", "2"]
    const [roomNo, setRoomNo] = useState("");

    const [file, setFile] = useState("");

    // Create a root reference
    const storage = getStorage();

    // Handle file selection
    const handleFileSelect = (file) => {
        setFile(file);
    };

    // // Handle file upload
    // const handleUpload = () => {
    //     if (file == null) {
    //         return;
    //     }

    //     // Create a reference to 'images/mountains.jpg'
    //     const storageRef = ref(storage, 'images/classes/' + removeSpecialCharactersFromString(value.name));
    //     // 'file' comes from the Blob or File API
    //     uploadBytes(storageRef, file).then((snapshot) => {
    //         console.log('Uploaded a blob or file!', file.name);
    //     });

    // };

    const addEditClassAndUpdateTable = () => {
        addEditClass(value);
        props.updateTable();
        // handleUpload();
    };

    return (
        <Layer>
            <Box fill align="center" pad="medium">
                <Box width="100%" direction="row" justify="end" margin={{ bottom: "small" }}>
                    <Box width="100%" margin={{ top: "xsmall" }}>
                        <Text size="xlarge" weight="bold">Add New Class</Text>
                    </Box>
                    <Button icon={<Close />} onClick={props.onClose} />
                </Box>
                <Box width="medium">
                    <Form
                        value={value}
                        onChange={(nextValue) => setValue(nextValue)}
                        onSubmit={({ value: nextValue }) => { console.log(nextValue); props.onClose(); addEditClassAndUpdateTable(); }}
                    >
                        <FormField label="Name" name="name" required>
                            <TextInput name="name" type="name" />
                        </FormField>

                        <FormField label="Schedule" name="schedule">
                            <Select
                                multiple
                                closeOnChange={false}
                                value={JSON.parse(value.schedule)}
                                options={weekDaysList}
                                onChange={option => handleWeekDaysSelect(option)}
                                required
                            />
                        </FormField>

                        {/* Disabling for now */}
                        {/* <FormField label="Photo" name="photo" required>
                            <FileInput
                                name="photo"
                                onChange={(event, { files }) => {
                                    console.log("onChange called", files);
                                    if (files.length > 0) {
                                        handleFileSelect(files[0]);
                                    }
                                }}
                            />
                        </FormField> */}

                        <FormField label="Description" name="Description">
                            <TextArea name="description" type="name" />
                        </FormField>

                        <FormField label="Cost" name="cost" required>
                            <TextInput name="cost" type="name" />
                        </FormField>

                        <FormField label="Class Teacher" name="teacher" required>
                            <Select
                                id="teacher"
                                name="teacher"
                                placeholder="Select Class Teacher"
                                value={classTeacher}
                                options={teachersList}
                                onChange={({ option }) => setClassTeacher(option)}
                            />
                        </FormField>

                        <FormField label="Room" name="room" required>
                            <Select
                                id="room"
                                name="room"
                                placeholder="Select Room Number"
                                value={roomNo}
                                options={roomNoList}
                                onChange={({ option }) => setRoomNo(option)}
                            />
                        </FormField>

                        <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                            <Button
                                onClick={() => setValue({ name: "", schedule: [] })}
                                type="reset"
                                label="Reset"
                            />
                            <Button type="submit" label="Update" primary />
                        </Box>
                    </Form>
                </Box>
            </Box>
        </Layer>
    )
};
import firebase from 'firebase/compat/app';
import { getDatabase, ref, set, onValue, remove } from 'firebase/database';
import { auth } from '..';

// Write data to Firebase
export function writeData(path, data) {
    const db = getDatabase();
    const reference = ref(db, path);
    set(reference, { ...data });
}

// Read data from Firebase
export function readData(path, callback) {
    const db = getDatabase();

    onValue(ref(db, path), (snapshot) => {
        callback(snapshot.toJSON());
    }, {
        onlyOnce: true
    });



    // const db = getDatabase();
    // const reference = ref(db, path);
    // console.log(111);
    // return onValue(reference, (snapshot) => {
    //     console.log(222);
    //     callback(snapshot.val().data);
    // }, { onlyOnce: true });
}

// Delete a specific path in Firebase
export function deletePath(path) {
    const db = getDatabase();
    const reference = ref(db, path);
    remove(reference);
}

export function UsersignUp(email) {
    const tempPassword = "defaultpassword1234";

    auth.createUserWithEmailAndPassword(email, tempPassword)
        .then(() => {
            console.log('User created!');
            auth.sendPasswordResetEmail(email)
                .then(() => {
                    console.log('Password Reset Email Sent');
                })
                .catch((error) => {
                    console.error(error);
                });
        })
        .catch((error) => {
            console.error(error);
        });
}

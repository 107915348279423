import { Box, Button, Form, FormField, Layer, Text, Select, TextInput, CheckBox, FileInput, TextArea } from 'grommet';
import { Close } from 'grommet-icons';
import React from 'react';
import { useState } from 'react';
import { signUpUser } from '../../api/firebaseHelper';
import { addInStudentList } from '../../api/students';
import { addEditUser } from '../../api/users';
import { userTypes } from "../../lib/constants";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { removeSpecialCharactersFromString } from '../../lib/stringHelper';

// TODO: Add validation for dupliate name addition
export const AddEditUserForm = (props) => {
    const defaultValue = props.userData ?? { userType: undefined, name: "", email: "", phoneNo: "", emergencyContactName: "", emergencyContact: "", dateOfBirth: "", photo: "", role: "", description: "", showInTeamsPage: false }
    const [value, setValue] = useState(defaultValue);

    const [file, setFile] = useState("");

    // Create a root reference
    const storage = getStorage();

    // Handle file selection
    const handleFileSelect = (file) => {
        setFile(file);
    };

    // Handle file upload
    const handleUpload = () => {
        if (file == null) {
            return;
        }

        // Create a reference to 'images/mountains.jpg'
        const storageRef = ref(storage, 'images/users/' + removeSpecialCharactersFromString(value.email) + '.' + file.type.split("/")[1]);
        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!', file.name);
        });

    };

    const addEditUserAndUpdateTable = () => {
        addEditUser(value);
        if (value.userType == "Student") {
            addInStudentList(value.name, value.email);
        }
        props.updateTable();
        // handleUpload();
    };

    const addUserInAuthenticationIfNewUser = () => {
        // Blocking the sign up for now
        // if (!props.userData) {
        //     signUpUser(value.email);
        // }
    }

    return (
        <Layer>
            <Box fill align="center" pad="medium">
                <Box width="100%" direction="row" justify="end" margin={{ bottom: "small" }}>
                    <Box width="100%" margin={{ top: "xsmall" }}>
                        <Text size="xlarge" weight="bold">{props.formType == "add" ? "Add New User" : "Edit User"}</Text>
                    </Box>
                    <Button icon={<Close />} onClick={props.onClose} />
                </Box>
                <Box height={value.userType ? "large" : "100%"} width="medium" overflow={value.userType ? "scroll" : ""}>
                    <Form
                        value={value}
                        onChange={(nextValue) => setValue(nextValue)}
                        onSubmit={({ value: nextValue }) => { console.log(nextValue); addUserInAuthenticationIfNewUser(); props.onClose(); addEditUserAndUpdateTable(); }}
                    >
                        <FormField label="User Type" name="userType" required>
                            <Select
                                id="userType"
                                name="userType"
                                placeholder="Select User Type"
                                value={value.userType}
                                // This line was modified. Might generate errors. Test while working on this part
                                options={props.userData ? [props.userData.userType] : Object.values(userTypes)}
                                onChange={({ option }) => { setValue({ ...defaultValue, userType: option }); }}
                            />
                        </FormField>

                        {value.userType && (
                            <>
                                <FormField label="Name" name="name" required>
                                    <TextInput name="name" type="name" />
                                </FormField>

                                <FormField label="Email" name="email" required>
                                    <TextInput name="email" type="email" disabled={props.userData} />
                                </FormField>

                                <FormField label="Phone Number" name="phoneNo" required>
                                    <TextInput name="phoneNo" type="tel" pattern="[0-9]{10}" />
                                </FormField>

                                {value.userType === "Student" && (
                                    <>
                                        <FormField label="Emergency Contact Name" name="emergencyContactName" required>
                                            <TextInput name="emergencyContactName" type="name" />
                                        </FormField>

                                        <FormField label="Emergency Contact" name="emergencyContact" required>
                                            <TextInput name="emergencyContact" type="tel" pattern="[0-9]{10}" />
                                        </FormField>

                                        <FormField label="Date of birth" name="dateOfBirth" required>
                                            <TextInput name="dateOfBirth" type="date" />
                                        </FormField>
                                    </>
                                )}

                                {/* Disabling for now */}
                                {/* <FormField label="Photo" name="photo" validate={fileList => (fileList == undefined || (fileList != undefined && fileList.length > 0 && fileList[0].type.startsWith("image"))) ? undefined : "Incorrect file type"}>
                                    <FileInput
                                        name="photo"
                                        onChange={(event, { files }) => {
                                            // const fileList = files;
                                            // for (let i = 0; i < fileList.length; i += 1) {
                                            //     const file = fileList[i];
                                            //     console.log(file.name);
                                            //     // Add check for image upload

                                            //     break;
                                            // }
                                            console.log("onChange called", files);
                                            if (files.length > 0) {
                                                handleFileSelect(files[0]);
                                            }
                                        }}
                                    />
                                </FormField> */}

                                {value.userType !== "Student" && (
                                    <>
                                        <FormField label="Role" name="role" required>
                                            <TextInput name="role" type="name" />
                                        </FormField>

                                        <FormField label="Description" name="description" required>
                                            <TextArea name="description" type="name" />
                                        </FormField>

                                        <FormField label="Show in Teams Page" name="showInTeamsPage">
                                            <CheckBox name="showInTeamsPage" tabIndex="-1" />
                                        </FormField>
                                    </>
                                )}
                            </>
                        )}

                        {/* 
                            Fields:
                                Name
                                Email
                                PhoneNo
                                Emergency Contact Name - Student
                                Emergency Contact Number - Student
                                Date of Birth - Student
                                Photo - Non Student
                                Role - Non Student
                                Description - Non Student
                                Show in Teams - Non Student
                         */}

                        <Box direction="row" justify="between" width="100%" height="xxsmall">
                            <Button
                                onClick={() => setValue(defaultValue)}
                                type="reset"
                                label="Reset"
                                alignSelf="center"
                            />
                            <Button type="submit" label="Submit" alignSelf="center" primary />
                        </Box>
                    </Form>
                </Box>
            </Box>
        </Layer >
    )
};

//import { Box, Grid, Grommet } from 'grommet';
import { Box, Card as GrommetCard, CardBody, CardHeader, Image, Text } from "grommet";
import {
    Analytics, ContactInfo, DocumentUser, Schedule, UserAdd, UserExpert
} from 'grommet-icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import  Card1  from '../card/Card1.jsx';
//import {Calligraphy, Classical, Experimental,Progressive} from '/Users/shalaka/Documents/flying-colors-arts-studio/frontend/src/lib/constants.js';
import { userTypes,Calligraphy, Classical, Experimental,Progressive } from '../../lib/constants';
import { getUserData } from '../../api/users';
import { StudentEnrollment } from "../studentEnrollment/StudentEnrollment.js";

function ClassesInfo() {
    return(
       
        <GrommetCard direction="row" gap="medium" margin-left={10} wrap>
        
            <Card1 message=  {Classical} onClick={StudentEnrollment}/>
            <Card1 message=  {Progressive}/>
            <Card1 message=  {Experimental}/>
            <Card1 message=  {Calligraphy}/>
        
            </GrommetCard>
            
            
    
    );
}

export default ClassesInfo
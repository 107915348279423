import React from 'react';
import { Box, Button, Text } from "grommet";
import {
    Plan
} from 'grommet-icons';
import { myCustomTheme } from "../App";
import { months } from "./Calendar";

export const Event = props => {
    return (
        <Box direction="column" background="accent-3"
            onClick={props.onClick}
            gap="small"
        >
            <Box direction="row">
                <Text>{months[props.event.date.month - 1] + "," + props.event.date.day}</Text>
                <Text>{props.event.text}</Text>
            </Box>
            {
                props.shouldExpand && (
                    <Box direction="column" gap="xxsmall" background="dark-3">
                        <Text>{props.event.description}</Text>
                        <Button primary icon={<Plan />} color="accent-3" label="Register" />
                    </Box>
                )
            }
        </Box>
    );
}
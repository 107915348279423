import { Button, Form, Select, Box, Text, TableHeader, Table, TableCell, TableRow, FormField, TextInput, Layer} from "grommet";
import { useEffect, useState } from "react";
import { Add, Trash } from 'grommet-icons';
import _ from "lodash";
import { getStudentCreditData, getStudentList, setStudentCreditData } from "../../api/students";
import { getAllClasses } from "../../api/classes";

const CheckInForm = (props) => {
    const [originalStudentOptions, setOriginalStudentOptions] = useState([]);
    const [studentOptions1, setStudentOptions] = useState([]);
    const [studentSearched, setStudentSearched] = useState('');

    useEffect(() => getStudentList((data) => {
        if (data != null && data != '') {
            const newStudentOptions = [];
            Object.values(data).forEach(val => newStudentOptions.push(val));
            setOriginalStudentOptions(newStudentOptions);
            setStudentOptions(newStudentOptions);
        }
    }), []);

    const handleStudentSearch = (value) => {
        setStudentSearched(value);
        setStudentOptions(originalStudentOptions.filter(option => option.toLowerCase().includes(value.toLowerCase())));
    }

    return (
        <Form>
            <FormField label='Student Name' value={props.studentName}>
                <Select
                    options={studentOptions1}
                    value={studentSearched}
                    onChange={({ value }) => props.setStudentName(value)}
                    placeholder={(props.studentName == undefined || props.studentName == "") ? 'Select a student' : props.studentName}
                    onSearch={handleStudentSearch}
                />
            </FormField>
        </Form>
    );
}

// Initial state for the modal
const initialState = {
    className: '',
    credits: '',
    showModal: false,
};

function CreditTable(props) {
    const [tableData, setTableData] = useState([]);

    const studentEmail = props.studentName.split(" ").pop();
    useEffect(() => getStudentCreditData(studentEmail, (data) => {
        if (data == null || data == '') {
            setTableData([]);
            return;
        }

        const newTableData = [];
        Object.values(data).forEach(val => newTableData.push(val));
        setTableData(newTableData);
    }), [props.studentName]);

    const [originalClassOptions, setOriginalClassOptions] = useState([]);
    const [classOptions1, setClassOptions] = useState([]);
    // const [classSearched, setClassSearched] = useState('');

    useEffect(() => getAllClasses((data) => {
        if (data != null && data != '') {
            const newClassOptions = [];
            Object.values(data).forEach(val => newClassOptions.push(val.name));
            setOriginalClassOptions(newClassOptions);
            setClassOptions(newClassOptions);
        }
    }), []);

    const handleClassSearch = (value) => {
        setState({ ...state, value });
        setClassOptions(originalClassOptions.filter(option => option.toLowerCase().includes(value.toLowerCase())));
    }

    const handleDelete = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this entry?");
        if (confirmDelete) {
            const newData = [...tableData];
            newData.splice(index, 1);
            setTableData(newData);
            setStudentCreditData(studentEmail, newData);

        }
    }

    const addInTable = (className, credits) => {
        let index = -1;
        tableData.forEach((row, index1) => {
            if (row.className == className) {
                index = index1;
            }
        })

        const newTableData = _.cloneDeep(tableData);
        if (index != -1) {
            newTableData[index] = { ...newTableData[index], availableCredits: newTableData[index].availableCredits + parseInt(credits) };
        } else {
            newTableData.push({ className, availableCredits: parseInt(credits) });
        }

        setTableData(newTableData);
        setStudentCreditData(studentEmail, newTableData);
    };

    // State for the modal
    const [state, setState] = useState(initialState);

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        if (state.className == '' || state.credits == '') {
            alert("Please enter all the fields");
        }

        try {
            if (parseInt(state.credits) != parseFloat(state.credits)) {
                throw new Error();
            }
            const credits = parseInt(state.credits);
            if (credits <= 0) {
                throw new Error();
            }
        } catch (error) {
            alert("Invalid value for credits");
            return;
        }

        addInTable(state.className, state.credits);

        // Close the modal
        setState({ ...state, showModal: false });
    };

    // Handle input change
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    return (
        <Box margin="small">
            <Box direction="row" justify="between" align="center" margin={{ top: "medium", bottom: "medium" }}>
                <Button icon={<Add />} label="Add New Credit" onClick={() => setState({ ...initialState, showModal: true })} />
            </Box>
            {state.showModal && (
                <Layer
                    onEsc={() => setState({ ...state, showModal: false })}
                    onClickOutside={() => setState({ ...state, showModal: false })}
                >
                    <Box margin="medium">
                        <Box margin={{ bottom: "10px" }}>
                            <Text size="large" weight="bold">New Credit</Text>
                        </Box>
                        <Form onSubmit={handleSubmit} margin={{ bottom: "10px" }}>
                            <FormField label="Class name">
                                <Select
                                    options={classOptions1}
                                    value={state.className}
                                    onChange={handleChange}
                                    name="className"
                                    onSearch={handleClassSearch}
                                />
                            </FormField>
                            <FormField label="Number of credits">
                                <TextInput
                                    value={state.credits}
                                    onChange={handleChange}
                                    name="credits"
                                />
                            </FormField>
                            <Box direction="row" justify="end" gap="medium">
                                <Button type="submit" label="Add" primary />
                                <Button
                                    onClick={() => setState({ ...state, showModal: false })}
                                    label="Cancel"
                                />
                            </Box>
                        </Form>
                    </Box>
                </Layer>
            )
            }


            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>
                            <Text>Class Name</Text>
                        </TableCell>
                        <TableCell>
                            <Text>Available Credits</Text>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <tbody>
                    {tableData.map((data, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Text>{data.className}</Text>
                            </TableCell>
                            <TableCell>
                                <Text>{data.availableCredits}</Text>
                            </TableCell>
                            <TableCell>
                                <Button icon={<Trash />} onClick={() => handleDelete(index)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </Box>
    );
}


export const StudentEnrollment = () => {
    const [studentName, setStudentName] = useState('');
    return (
        <>
            <CheckInForm studentName={studentName} setStudentName={setStudentName} />
            {studentName != '' && <CreditTable studentName={studentName} />}
            <Box height={studentName == '' ? "600px" : "500px"}></Box>
        </>
    );
};
